.loader {
    position: relative;
    box-sizing: border-box;
    background-image: url('../images/favicon.png');
    background-size: cover; /* Resize the background image to cover the entire container */
    background-position: center; /* Center the image */
    height: 100px; /* You must set a specified height */
    width: 100px;
    animation: envFloating 0.75s ease-in infinite alternate;
  }
    
  @keyframes envFloating {
    0% { transform: translate(-2px, -8px)}
    100% { transform: translate(0, 5px)}
  }